import {dispatchPPApiCall} from '../util/apiCallGenerator';
import getPage from '../util/getPage';
import {getFilterUrl} from '../../util/Filter';
import {RSAA} from 'redux-api-middleware';
import Injector from '../../core/utils/Injector';
import {notifySuccess} from './notificationActions';
import {createWorkflow} from './workflowActions';
import {Link} from 'react-router-dom';
import React, {Fragment} from 'react';
import {readCookie} from '../../util/TLDCookies';
import {omit, get} from 'lodash';

export function getLines(pageNumber = 1) {
  return (dispatch, getState) => {
    const {pages, pageSize} = getState().lines;
    const current = getState().filters.line.current;

    return getPage(
        dispatch,
        'GET_LINES',
        'GET', `/worksorderlines`,
        pages, pageSize, pageNumber, getFilterUrl(current).join('&'),
    );
  };
}

export function searchLines(pageNumber = 1, refetch = false) {
  return (dispatch, getState) => {
    const {search, searchPages, searchPageSize} = getState().lines;
    const current = getState().filters.line.current;

    return getPage(
        dispatch,
        'GET_LINES_SEARCH',
        'GET', '/worksorderlines/_/search',
        searchPages, searchPageSize, pageNumber,
        `search=${search}&${getFilterUrl(current).join('&')}`, {term: search},
        refetch,
    );
  };
}

export function getPageForLine(line) {
  return (dispatch, getState) => {
    return dispatchPPApiCall(
        dispatch,
        'GET_LINES',
        'GET',
        `/worksorderlines?size=${getState().lines.pageSize}&line=${line}`,
        undefined, Array(3).fill({meta: {line, filters: ''}}),
    );
  };
}

export function getProjectLines(pageNumber = 1, filters='') {
  return (dispatch, getState) => {
    const {lines, pageSize, project} = getState().project;
    const projectId = project.id;
    const meta = {project: projectId};

    return getPage(
        dispatch,
        'GET_PROJECT_LINES',
        'GET', `/worksorderlines`,
        lines, pageSize, pageNumber, `project=${projectId}&${filters}`, meta,
    );
  };
}

export function getOrderLines(orderId, pageNumber = 1) {
  return (dispatch, getState) => {
    const {lines, pageSize} = getState().order;
    const meta = {order: orderId};

    return getPage(
        dispatch,
        'GET_ORDER_LINES',
        'GET', '/worksorderlines',
        lines, pageSize, pageNumber, `order=${orderId}`, meta,
    );
  };
}

export function getLine(lineId) {
  return (dispatch) => (
    dispatchPPApiCall(
        dispatch,
        'GET_LINE',
        'GET', `/worksorderlines/${lineId}`,
        undefined, Array(3).fill({meta: {lineId}}),
    )
  );
}

export function createLine(submitData) {
  return async (dispatch) => {
    const line = omit(submitData, ['workflowTemplate']);
    const templateId = ('workflowTemplateId' in submitData) ?
      submitData.workflowTemplateId :
      null;
    const amendedDurations = ('amendedDurations' in submitData) ?
      submitData.amendedDurations : null;
    const skip = ('skip' in submitData) ?
      submitData.skip : null;

    const createLineResponse = await dispatchPPApiCall(
        dispatch,
        'CREATE_LINE',
        'POST',
        '/worksorderlines',
        line,
    );

    const lineId = get(createLineResponse, 'payload.id', null);
    if (lineId === null) {
      return;
    }

    if (templateId) {
      const createWorkflowResponse = await dispatch(createWorkflow({
        worksorderline: lineId,
        templateId: templateId,
        amendedDurations: amendedDurations,
        skip: skip,
      }));

      const workflowId = ('id' in createWorkflowResponse.payload) ? createWorkflowResponse.id : null;

      if (workflowId === null) {
        return;
      }
    }

    const successfulMsgText = 'Line created successfully.';

    dispatch(
        notifySuccess(
            <Fragment>
              {successfulMsgText} {lineId && <Link to={`/lines/${lineId}`}>View line</Link>}
            </Fragment>,
        ),
    );
  };
}

export function updateLine(id, line) {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        'UPDATE_LINE',
        'PATCH', `/worksorderlines/${id}`,
        line,
    );
  };
}

export function createLines(project, file, workflowTemplateId, amendedDurations) {
  return (dispatch) => {
    const body = new FormData();

    body.append('lines', file, 'lines.csv');
    body.append('project', project);
    workflowTemplateId && body.append('workflowTemplateId', workflowTemplateId);
    body.append('amendedDurations', JSON.stringify(amendedDurations));

    // Are we impersonating a user?
    const impersonateUserEmail = readCookie('IMPERSONATE_USER_EMAIL');

    return dispatch({
      [RSAA]: {
        endpoint: Injector.config('pp_api_endpoint')+'/worksorderlines',
        method: 'POST',
        headers: {
          'Accept': 'application/json;version='+Injector.config('pp_api_version'),
          ...(impersonateUserEmail ? {'x-switch-user': impersonateUserEmail} : {}),
        },
        body,
        credentials: 'include',
        types: [
          'CREATE_LINES_REQUEST',
          'CREATE_LINES_COMPLETE',
          'CREATE_LINES_FAILURE',
        ],
      },
    });
  };
}

export function completeImport() {
  return (dispatch, getState) => {
    const importId = getState().createLines.importId;
    if (importId === null) {
      return Promise.reject(new Error('No import to complete'));
    }

    return dispatchPPApiCall(
        dispatch, 'COMPLETE_IMPORT',
        'PATCH', `/imports/${importId}`,
        {'completed': true},
    );
  };
}

export function getProjectsForLines() {
  return (dispatch) => (
    dispatchPPApiCall(
        dispatch,
        'GET_PROJECTS_FOR_LINES',
        'GET', '/projects?all=1&addLine=1',
    )
  );
}

export function getFieldDefinitions(obj = {}) {
  return (dispatch) => {
    let query = '?';
    if (typeof obj.line !== 'undefined') {
      query = query + `line=${obj.line}`;
    } else if (typeof obj.client !== 'undefined') {
      query = query + `client=${obj.client}`;
    }

    return dispatchPPApiCall(
        dispatch,
        'GET_FIELD_DEFINITIONS',
        'GET', `/fielddefinitions${query}`,
    );
  };
}

export function getClientWorkflowTemplates(clientId) {
  return (dispatch) => dispatchPPApiCall(
      dispatch,
      'GET_WORKFLOW_TEMPLATES',
      'GET',
      `/workflowtemplates?client=${clientId}`,
  );
}

export function getWorkflowTemplates(project) {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        'GET_WORKFLOW_TEMPLATES',
        'GET', `/workflowtemplates?project=${project}`,
    );
  };
}

export function resetFieldDefinitions() {
  return (dispatch) => {
    return dispatch({
      type: 'Reset line field definitions',
    });
  };
}

export function resetWorkflowTemplates() {
  return (dispatch) => {
    return dispatch({
      type: 'Reset line workflow templates',
    });
  };
}
